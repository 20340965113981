import React from "react";
import Footer from "../../../components/shared/Footer/Footer";
import HomeTwoNavbar from "../../Home/HomeTwoNavbar/HomeTwoNavbar";
import HomeArea from "../HomeArea/HomeArea";
import Directories from "../Home/BannerArea/BannerArea";
import AboutArea from "./AboutArea/AboutArea";
import ChoseArea from "./ChoseArea/ChoseArea";
import HeroSection from "./HeroSection/HeroSection";
import ProgressArea from "./ProgressArea/ProgressArea";
import ServiceHome from "../Home/ServicesHome/ServiceHome";
import WorkingProcess from "../../../pages/About/About/WorkingProcess/WorkingProcess";
import ContactMap from "../../Contact/Contact/ContactMap/ContactMap";
import Modal from "../Home/Modal/Modal";

const Home = () => {
  return (
    <>
      <HomeTwoNavbar />
      <HeroSection />
      <HomeArea />
      <Directories />
      <ProgressArea />
      <AboutArea />
      <WorkingProcess />
      <ServiceHome />
      <ChoseArea />
      <ContactMap />
      <Modal />
      <Footer />
    </>
  );
};

export default Home;
